import { template as template_ad828ddb80f0426fb6a31ff507dd5197 } from "@ember/template-compiler";
const FKText = template_ad828ddb80f0426fb6a31ff507dd5197(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
