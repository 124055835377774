import { template as template_cea74bdf65d14484bd44bb7b062b2d7b } from "@ember/template-compiler";
const SidebarSectionMessage = template_cea74bdf65d14484bd44bb7b062b2d7b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
