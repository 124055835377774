import { template as template_13de5edd9e1c4324bf7b82172956b993 } from "@ember/template-compiler";
const FKLabel = template_13de5edd9e1c4324bf7b82172956b993(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
