import { template as template_97a4470723e7469c8c9951ba6b1b438b } from "@ember/template-compiler";
const FKControlMenuContainer = template_97a4470723e7469c8c9951ba6b1b438b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
